const rootUrl = 'https://api.portal.mdcn.gov.ng';

const loadingBladeUrl = rootUrl+'/storage/images/loading.gif';
const blankImageUrl = rootUrl+'/storage/images/blank-photo.png';
const pdfUrl = rootUrl+'/storage/images/certificate.png';
const logoutUserUrl = rootUrl+'/logout';
const loginUserUrl = rootUrl+'/admin-login';
const resetPasswordUrl = rootUrl+'/reset-admin-password';
const printCertificateUrl = rootUrl+'/print-certificate'; 
// user module urls
const resetAdminPasswordUrl = rootUrl+'/reset-admin-password';
const updatePasswordUrl = rootUrl+'/update/password';
//Application module urls
const profileUserUrl = rootUrl+'/get-admin-profile';
const dashboardInfoUrl = rootUrl+'/get-admin-dashboard-info';
const pendingSignupsUrl = rootUrl+'/get-pending-signups';
const changePasswordUrl = rootUrl+'/change-admin-password';
const updatePendingSignupsUrl = rootUrl+'/update-pending-signups';
const applicationTypeUrl = rootUrl+'/get-application-type';
const getExactApplicationsUrl = rootUrl+'/get-exact-applications';
const applicationUrl = rootUrl+'/admin-get-user-application';
const locationsUrl = rootUrl+'/get-locations';
const updateUserCpdPointUrl = rootUrl+'/update-cpd-point';
const updateUserDataUrl = rootUrl+'/update-user-data';
const approveApplicationUrl = rootUrl+'/admin-approve-application';
const rejectApplicationUrl = rootUrl+'/reject-application';
const searchExactApplicationsUrl = rootUrl+'/search-user-applications';
const getInternshipRecordsUrl = rootUrl+'/get-user-internship';
const updateUserLicenseYearUrl = rootUrl+'/update-license-year';
const getOldAQApplicationUrl = rootUrl+'/get-old-aq';
const approveOldAQApplicationUrl = rootUrl+'/approve-old-aq';
const uploadEcisFileUrl = rootUrl+'/upload-ecis-file';
const sendUserEcisMailUrl = rootUrl+'/send-ecis-mail';
const getDashboardInfoUrl = rootUrl+'/get-dashboard-info';
const getWorldSchoolsUrl = rootUrl+'/get-world-schools';
// doctor module urls
const getAUserUrl = rootUrl+'/admin-get-user';
const updateUserProfileUrl = rootUrl+'/admin-update-user-profile';
const getUserAddQualUrl = rootUrl+'/get-user-additional-qualifications';
const getAdditionalQulificationsUrl = rootUrl+'/get-additional-qualifications';
const getInstitutionsUrl = rootUrl+'/get-institutions';
const enableUserEditUrl = rootUrl+'/enable-user-profile';
const getRegistrationTypesUrl = rootUrl+'/get-registration-types';
const getSubRegistrationTypesUrl = rootUrl+'/get-sub-registration-types';
const updateAdditionalQualificationUrl = rootUrl+'/update-additional-qualification';
const getUserApplicationsUrl = rootUrl+'/get-user-applications';
const getUserMedicalSchoolUrl = rootUrl+'/get-user-medical-school';
const updateMedicalSchoolRecordUrl = rootUrl+'/update-user-medical-school';
const deleteUserAcountUrl = rootUrl+'/delete-user-account';
const deleteUserAqUrl = rootUrl+'/delete-user-aq';
const getExactInstitutionsUrl = rootUrl+'/get-assessment-institutions';
const resetDoctorPasswordUrl = rootUrl+'/reset-doctor-password';
const deleteAnApplicationUrl = rootUrl+'/admin-delete-application';
// setting module urls
const getAdminUnitsUrl = rootUrl+'/get-admin-units';
const getUnitMembersUrl = rootUrl+'/get-admin-unit-members';
const getMemberPrivilegesUrl = rootUrl+'/get-member-privileges';
const updateMemberPrivilegeUrl = rootUrl+'/update-member-privilege';
const getAdminDepartmentsUrl = rootUrl+'/get-admin-departments';
const saveDepartmentUrl = rootUrl+'/save-admin-department';
const deleteAdminDepartmentUrl = rootUrl+'/delete-admin-department';
const saveUnitUrl = rootUrl+'/save-admin-unit';
const deleteAdminUnitUrl = rootUrl+'/delete-admin-unit';
const updateUserUnitUrl = rootUrl+'/update-user-unit';
const enableAdminUserUrl = rootUrl+'/enable-admin-user';
const getAdminUsersUrl = rootUrl+'/get-admin-users';
const createAdminUserUrl = rootUrl+'/create-admin-user';
const searchAdminUserUrl = rootUrl+'/search-admin-user';
const getDepartmentMembersUrl = rootUrl+'/get-admin-department-members';
// payment module urls
const getApplicationTypesUrl = rootUrl+'/get-application-types';
const getUserProfileUrl = rootUrl+'/get-user-data';
const getRemitaPaymentStatusUrl = rootUrl+'/get-rrr-status';
const updateApplicationTransactionUrl = rootUrl+'/admin-update-application';
const loadRRRDetailsUrl = rootUrl+'/get-rrr-details';
const removeRRRPaymentUrl = rootUrl+'/remove-rrr-payment';
const verifyMwanPaymentUrl = rootUrl+'/verify/mwan/payment';
// Insurance module urls
const getIndemnityUrl = rootUrl+'/get-user-insurance';
const updateInsuranceRecordUrl = rootUrl+'/update-user-insurance';
// other settings urls
const getPaymentItemsUrl = rootUrl+'/get-payment-items';
const updatePaymentBreakdownUrl = rootUrl+'/update-payment-breakdown';
const deletePaymentBreakdownUrl = rootUrl+'/delete-payment-breakdown';
const updatePaymentSurchargeUrl = rootUrl+'/update-payment-surcharge';
const getEmailItemsUrl = rootUrl+'/get-email-items';
const updateEmailUrl = rootUrl+'/update-email';
const getSpecializationsUrl = rootUrl+'/get-admin-specializations';
const updateSpecializationUrl = rootUrl+'/update-specialization';
const deleteUserSpecializationUrl = rootUrl+'/delete-specialization';
const updateSubSpecializationUrl = rootUrl+'/update-sub-specialization';
const getAQconfigsUrl = rootUrl+'/get-aq-configs';
const updateQualificationUrl = rootUrl+'/update-qualification';
const deleteUserQualificationUrl = rootUrl+'/delete-qualification';
const getCountriesUrl = rootUrl+'/get-countries';
const updateCountryUrl = rootUrl+'/update-country';
const deleteUserCountryUrl = rootUrl+'/delete-country';
const getStatesUrl = rootUrl+'/get-states';
const updateStateUrl = rootUrl+'/update-state';
const deleteUserStateUrl = rootUrl+'/delete-state';
const updateLgaUrl = rootUrl+'/update-lga';
const deleteUserLgaUrl = rootUrl+'/delete-lga';
const getAppointmentPostingsUrl = rootUrl+'/get-appointment-posting-depts';
const updatePostingDepartmentUrl = rootUrl+'/update-appointment-posting-dept';
const deleteAppointmentPostingUrl = rootUrl+'/delete-appointment-posting-dept';
const getSiteParametersUrl = rootUrl+'/get-site-parameters';
const updateSiteParameterUrl = rootUrl+'/update-site-parameter';
const searchAQconfigsUrl = rootUrl+'/search-aq-configs';
const searchSpecializationsUrl = rootUrl+'/search-admin-specializations';
const searchCountriesUrl = rootUrl+'/search-countries';
const getLetterItemsUrl = rootUrl+'/get-letter-config-items';
const updateLetterUrl = rootUrl+'/update-letter-config';
// Reporting urls
const getPaymentsUrl = rootUrl+'/get-all-payments';
const getFilteredPaymentsUrl  = rootUrl+'/get-filtered-payments';
const getApplicationsUrl = rootUrl+'/get-all-applications';
const getFilteredApplicationUrl = rootUrl+'/get-filtered-applications';
const getAuditTrailRecordsUrl = rootUrl+'/get-audit-trail-records';
const getFilteredAuditTrailRecordsUrl = rootUrl+'/get-filtered-audit-trail-records';
const getDoctorsProfileUrl = rootUrl+'/get-doctors-profile';
const getFilteredDoctorsProfileUrl = rootUrl+'/get-filtered-doctors-profile';
const getMedicalEducationPaymentsUrl = rootUrl+'/get-medical-education-payments';
// Discipline urls
const getSanctionsUrl = rootUrl+'/get-all-sanctions';
const getSearchedSanctionsUrl = rootUrl+'/get-searched-sanctions';
const removeUserSanctionUrl = rootUrl+'/remove-user-sanction';
const getUsersUrl = rootUrl+'/get-all-users';
const addUserSanctionUrl = rootUrl+'/add-user-sanction';
const getExitRegisterUrl = rootUrl+'/get-exit-register';
const searchExitRegisterUrl = rootUrl+'/search-exit-register';
const removeUserExitRegisterUrl = rootUrl+'/remove-user-exit-register';
const addUserExitRegisterUrl = rootUrl+'/add-exit-register';
const addOfflineSanctionListUrl = rootUrl+'/add-offline-sanction';
const addOfflineExitRegisterUrl = rootUrl+'/add-offline-exit-register';
const getExactExitRegisterReportsUrl = rootUrl+'/get-exit-register-reports';
const changeExitRegisterReportStatusUrl = rootUrl+'/change-exit-report-status';
const deleteExitRegisterReportUrl = rootUrl+'/delete-exit-register-report';
const getComplaintsUrl = rootUrl+'/get-complaints-reporting';
const getSanctionsReportingUrl = rootUrl+'/get-sanctions-reporting';
const quackReportsReportingUrl = rootUrl+'/quack-reports-reporting';
const quacksReportingUrl = rootUrl+'/quacks-reporting';
// Schools Urls
const getSchoolsUrl = rootUrl+'/get-schools';
const removeSchoolUrl = rootUrl+'/delete-school';
const createSchoolUrl = rootUrl+'/add-new-school';
const updateSchoolStatusUrl = rootUrl+'/update-school-status';
const getSearchedSchoolsUrl = rootUrl+'/search-schools';
const getForeignSchoolsUrl = rootUrl+'/get-foreign-schools';
const createForeignSchoolUrl = rootUrl+'/add-foreign-school';
const removeForeignSchoolUrl = rootUrl+'/delete-foreign-school';
// Inspectorate Urls
const getExactComplaintsUrl = rootUrl+'/get-doctor-complaints';
const changeComplaintStatusUrl = rootUrl+'/change-complaint-status';
const getExactReportsUrl = rootUrl+'/get-quack-reports';
const changeReportStatusUrl = rootUrl+'/change-report-status';
const addToQuacksListUrl = rootUrl+'/add-quacks-list';
const getQuacksUrl = rootUrl+'/get-quacks-list';
const removeQuackFromListUrl = rootUrl+'/remove-quack-list';
const getSearchedQuacksUrl = rootUrl+'/get-searched-quacks';
const deleteComplaintDataUrl = rootUrl+'/delete-complaint';
// assessment Urls
const addAssessmentBatchUrl = rootUrl+'/add-assessment-batch';
const getBatchesUrl = rootUrl+'/get-assessment-batches';
const getBatchPaymentsUrl = rootUrl+'/get-assessment-batch-payments';
const addAssessmentBatchPaymentUrl = rootUrl+'/add-assessment-batch-payment';
const deleteBatchPaymentUrl = rootUrl+'/delete-assessment-batch-payment';
const getBatchPaymentBreakdownsUrl = rootUrl+'/get-batch-payment-breakdowns';
const addPaymentBreakdownUrl = rootUrl+'/add-batch-payment-breakdown';
const deleteBatchPaymentBreakdownUrl = rootUrl+'/delete-batch-payment-breakdown';
const completeAssessmentBatchUrl = rootUrl+'/complete-assessment-batch';
const deleteAssessmentBatchUrl = rootUrl+'/delete-assessment-batch';
const changeBatchStatusUrl = rootUrl+'/change-batch-status';
const getSearchedBatchesUrl = rootUrl+'/search-assessment-batches';
const getAssessmentTypesUrl = rootUrl+'/get-assessment-types';
const addAssessmentTypeUrl = rootUrl+'/add-assessment-type';
const deleteAssessmentTypeUrl = rootUrl+'/delete-assessment-type';
const getAssessmentApplicationsUrl = rootUrl+'/get-assessment-applications';
const getAssessmentApplicantUrl = rootUrl+'/get-assessment-applicant-info';
const approveAssessmentApplicationUrl = rootUrl+'/approve-assessment-application';
const saveAssessmentApplicantDataUrl = rootUrl+'/save-assessment-applicant-data';
const addPreviousAssessmentEntryUrl = rootUrl+'/save-previous-assessment-entry';
const getPreviousAssessmentEntriesUrl = rootUrl+'/get-previous-assessment-entries';
const deletePreviousAssessmentBatchUrl = rootUrl+'/delete-previous-assessment-entry';
const getAssessmentPaymentsUrl = rootUrl+'/get-assessment-payments';
const changeSeatStatusUrl = rootUrl+'/update-assessment-seat-status';
// Hospital Urls
const createHospitalUrl = rootUrl+'/create-hospital';
const getHospitalsUrl = rootUrl+'/get-hospitals';
const deleteHospitalUrl = rootUrl+'/delete-hospital';
const changeHospitalStatusUrl = rootUrl+'/change-hospital-status';
const getSearchedHospitalsUrl = rootUrl+'/search-hospitals';
const getTrainingHospitalApplicationsUrl = rootUrl+'/training-hospitals-applications';
const getHospitalApplicationUrl = rootUrl+'/get-hospital-application';
const approveHospitalApplicationUrl = rootUrl+'/approve-hospital-application';
const getAccreditedHospitalsUrl = rootUrl+'/get-accredited-hospitals';
const updateAccreditedHospitalUrl = rootUrl+'/update-accredited-hospital';
const deleteAccreditedHospitalUrl = rootUrl+'/delete-accredited-hospital';
const getTrainingHospitalsUrl = rootUrl+'/get-training-hospitals';
const changeTrainingHospitalStatusUrl = rootUrl+'/change-training-hospital-status';
const getTrainingHospitalPaymentsUrl = rootUrl+'/get-training-hospital-payments';
const getTrainingHospitalsReportingUrl = rootUrl+'/get-training-hospitals-reporting';
const notifyHospitalVisitationUrl = rootUrl+'/notify-training-hospitals-date';
const getTrainingHospitalUrl = rootUrl+'/get-training-hospital';
const updateHospitalProfileUrl = rootUrl+'/update-training-hospital';
const enableHospitalEditUrl = rootUrl+'/enable-training-hospital';
const deleteHospitalAcountUrl = rootUrl+'/delete-training-hospital';
// Notifications Urls
const getNotificationsUrl = rootUrl+'/get-notifications';
const deleteNotificationUrl = rootUrl+'/delete-notification';
const createNotificationUrl = rootUrl+'/create-notification';
// Provider Urls
const getCpdProviderUrl = rootUrl+'/get-cpd-provider';
const updateCpdProfileUrl = rootUrl+'/update-cpd-provider';
const enableCpdEditUrl = rootUrl+'/enable-cpd-provider';
const deleteCpdAcountUrl = rootUrl+'/delete-cpd-provider';
const getCpdApplicationsUrl = rootUrl+'/admin-cpd-applications';
const getCpdApplicationUrl = rootUrl+'/get-cpd-application';
const approveCpdApplicationUrl = rootUrl+'/approve-cpd-application';
const getAccreditedCpdsUrl = rootUrl+'/accredited-cpds';
const modifyDeleteCpdUrl = rootUrl+'/modify-accredited-cpd';
const getCpdProvidersUrl = rootUrl+'/get-cpd-providers';
const getCpdProviderPaymentsUrl = rootUrl+'/get-cpd-providers-payments';
const getCpdProvidersReportUrl = rootUrl+'/get-cpd-providers-report';
const notifyCpdVisitationUrl = rootUrl+'/notify-cpd-providers-date';
// Training Schools Urls
const getTrainingSchoolsUrl = rootUrl+'/get-training-schools';
const enableSchoolEditUrl = rootUrl+'/enable-training-school';
const getTrainingSchoolUrl = rootUrl+'/get-training-school';
const updateSchoolProfileUrl = rootUrl+'/update-training-school';
const deleteSchoolAcountUrl = rootUrl+'/delete-training-school';
const getSchoolApplicationsUrl = rootUrl+'/training-school-applications';
const getSchoolApplicationUrl = rootUrl+'/get-school-application';
const approveSchoolApplicationUrl = rootUrl+'/approve-school-application';
const getAccreditedSchoolsUrl = rootUrl+'/accredited-schools';
const modifyDeleteSchoolUrl = rootUrl+'/modify-accredited-school';
const getAdvisorySchoolsUrl = rootUrl+'/advisory-schools';
const modifyDeleteAdvisorySchoolUrl = rootUrl+'/modify-advisory-school';
const getTrainingSchoolPaymentsUrl = rootUrl+'/get-training-schools-payments';
const getIndexingApplicationsUrl = rootUrl+'/admin-indexing-applications';
const getIndexingApplicationUrl = rootUrl+'/admin-indexing-application';
const updateIndexingStudentUrl = rootUrl+'/update-indexing-student';
const approveSchoolListUrl = rootUrl+'/approve-indexing-list';
const getTrainingSchoolsReportingUrl = rootUrl+'/get-training-schools-reporting';
const getIndexingReportingUrl = rootUrl+'/admin-indexing-reporting';
const notifySchoolVisitationUrl = rootUrl+'/notify-training-schools-date';
const updateIndexingSerialNoUrl = rootUrl+'/update-indexing-serial-numbers';
// Attachment Program Urls
const getAttachmentBatchesUrl = rootUrl+'/get-attachment-batches';
const addAttachmentBatchUrl = rootUrl+'/add-attachment-batch';
const changeDeleteAttachmentBatchStatusUrl = rootUrl+'/change-attachment-batch-status';
const getAttachmentCentresUrl = rootUrl+'/get-attachment-centres';
const addAttachmentCentreUrl = rootUrl+'/add-attachment-centre';
const changeDeleteAttachmentCentreUrl = rootUrl+'/change-attachment-centre-status';
const getAttachmentBatchCentresUrl = rootUrl+'/get-attachment-batch-centres';
const updateAttachmentCentreQuotaUrl = rootUrl+'/update-attachment-centre-quota';
const getAttachmentApplicationsUrl = rootUrl+'/get-attachment-applications';
const getAttachmentApplicantUrl = rootUrl+'/get-attachment-applicant-info';
const rejectAttachmentApplicationUrl = rootUrl+'/reject-attachment-application';
const addToPostingListUrl = rootUrl+'/add-attachment-posting-list';
const filterAttachmentApplicationsUrl = rootUrl+'/filter-attachment-applications';
const postAttachmentApplicantsUrl = rootUrl+'/post-attachment-applicants';
const cancelApplicantPostingUrl = rootUrl+'/cancel-attachment-posting';
const getAttachmentDashboardInfoUrl = rootUrl+'/get-attachment-dashboard-info';
const resetAttachmentCentrePasswordUrl = rootUrl+'/reset-attachment-centre-password';
const getAttachmentPaymentsUrl = rootUrl+'/get-attachment-payments';
// Ticketing Urls
const getTicketingBatchesUrl = rootUrl+'/get-ticketing-batches';
const addTicketingBatchUrl = rootUrl+'/add-ticketing-batch';
const uploadTicketingFileUrl = rootUrl+'/upload-ticket-inductees';
const getTicketBatchInducteesUrl = rootUrl+'/get-ticket-batch-inductees';
const updateBatchInducteeUrl = rootUrl+'/update-ticket-batch-inductees';
// Remediation Urls
const getRemediationBatchesUrl = rootUrl+'/get/remediation/batches';
const addRemediationBatchUrl = rootUrl+'/add/remediation/batch';
const getRemediationApplicationsUrl = rootUrl+'/get/remediation/applications';
const getRemediationApplicationUrl = rootUrl+'/get/remediation/application';
const approveRemediationApplicationUrl = rootUrl+'/approve/remediation/application';
const getExportRemedationListUrl = rootUrl+'/export/remediation/application';
// Other Details
const perPage = 20;
const exportPerSheet = 50000;
const assessmentPrintRange = 100;
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcYkqZgiSRRmYOwvjce1';

const state = {
    verifyMwanPaymentUrl: verifyMwanPaymentUrl,
    approveRemediationApplicationUrl: approveRemediationApplicationUrl,
    getRemediationApplicationUrl: getRemediationApplicationUrl,
    getRemediationApplicationsUrl: getRemediationApplicationsUrl,
    addRemediationBatchUrl: addRemediationBatchUrl,
    getRemediationBatchesUrl: getRemediationBatchesUrl,
    resetAdminPasswordUrl: resetAdminPasswordUrl,
    deleteHospitalAcountUrl: deleteHospitalAcountUrl,
    enableHospitalEditUrl: enableHospitalEditUrl,
    updateHospitalProfileUrl: updateHospitalProfileUrl,
    getTrainingHospitalUrl: getTrainingHospitalUrl,
    deleteAnApplicationUrl: deleteAnApplicationUrl,
    getMedicalEducationPaymentsUrl: getMedicalEducationPaymentsUrl,
    quacksReportingUrl: quacksReportingUrl,
    quackReportsReportingUrl: quackReportsReportingUrl,
    getSanctionsReportingUrl: getSanctionsReportingUrl,
    getComplaintsUrl: getComplaintsUrl,
    getWorldSchoolsUrl: getWorldSchoolsUrl,
    deleteComplaintDataUrl: deleteComplaintDataUrl,
    updateIndexingSerialNoUrl: updateIndexingSerialNoUrl,
    notifySchoolVisitationUrl: notifySchoolVisitationUrl,
    notifyHospitalVisitationUrl: notifyHospitalVisitationUrl,
    updateLetterUrl: updateLetterUrl,
    getLetterItemsUrl: getLetterItemsUrl,
    notifyCpdVisitationUrl: notifyCpdVisitationUrl,
    getIndexingReportingUrl: getIndexingReportingUrl,
    getCpdProvidersReportUrl: getCpdProvidersReportUrl,
    getTrainingHospitalsReportingUrl: getTrainingHospitalsReportingUrl,
    getTrainingSchoolsReportingUrl: getTrainingSchoolsReportingUrl,
    updateBatchInducteeUrl: updateBatchInducteeUrl,
    getTicketBatchInducteesUrl: getTicketBatchInducteesUrl,
    uploadTicketingFileUrl: uploadTicketingFileUrl,
    addTicketingBatchUrl: addTicketingBatchUrl,
    getTicketingBatchesUrl: getTicketingBatchesUrl,
    resetDoctorPasswordUrl : resetDoctorPasswordUrl,
    getAttachmentPaymentsUrl : getAttachmentPaymentsUrl,
    resetAttachmentCentrePasswordUrl: resetAttachmentCentrePasswordUrl,
    getAttachmentDashboardInfoUrl: getAttachmentDashboardInfoUrl,
    cancelApplicantPostingUrl: cancelApplicantPostingUrl,
    postAttachmentApplicantsUrl: postAttachmentApplicantsUrl,
    filterAttachmentApplicationsUrl: filterAttachmentApplicationsUrl,
    addToPostingListUrl: addToPostingListUrl,
    rejectAttachmentApplicationUrl: rejectAttachmentApplicationUrl,
    getAttachmentApplicantUrl: getAttachmentApplicantUrl,
    getAttachmentApplicationsUrl: getAttachmentApplicationsUrl,
    updateAttachmentCentreQuotaUrl: updateAttachmentCentreQuotaUrl,
    getAttachmentBatchCentresUrl: getAttachmentBatchCentresUrl,
    changeDeleteAttachmentCentreUrl: changeDeleteAttachmentCentreUrl,
    addAttachmentCentreUrl: addAttachmentCentreUrl,
    getAttachmentCentresUrl: getAttachmentCentresUrl,
    changeDeleteAttachmentBatchStatusUrl: changeDeleteAttachmentBatchStatusUrl,
    addAttachmentBatchUrl: addAttachmentBatchUrl,
    getAttachmentBatchesUrl: getAttachmentBatchesUrl,
    getExactInstitutionsUrl: getExactInstitutionsUrl,
    changeSeatStatusUrl: changeSeatStatusUrl,
    getAssessmentPaymentsUrl: getAssessmentPaymentsUrl,
    removeForeignSchoolUrl: removeForeignSchoolUrl,
    createForeignSchoolUrl: createForeignSchoolUrl,
    getForeignSchoolsUrl: getForeignSchoolsUrl,
    approveSchoolListUrl: approveSchoolListUrl,
    updateIndexingStudentUrl: updateIndexingStudentUrl,
    getIndexingApplicationUrl: getIndexingApplicationUrl,
    getIndexingApplicationsUrl: getIndexingApplicationsUrl,
    getTrainingSchoolPaymentsUrl: getTrainingSchoolPaymentsUrl,
    modifyDeleteAdvisorySchoolUrl: modifyDeleteAdvisorySchoolUrl,
    getAdvisorySchoolsUrl: getAdvisorySchoolsUrl,
    modifyDeleteSchoolUrl: modifyDeleteSchoolUrl,
    getAccreditedSchoolsUrl: getAccreditedSchoolsUrl,
    approveSchoolApplicationUrl: approveSchoolApplicationUrl,
    getSchoolApplicationUrl: getSchoolApplicationUrl,
    getSchoolApplicationsUrl: getSchoolApplicationsUrl,
    deleteSchoolAcountUrl: deleteSchoolAcountUrl,
    updateSchoolProfileUrl: updateSchoolProfileUrl,
    getTrainingSchoolUrl: getTrainingSchoolUrl,
    enableSchoolEditUrl: enableSchoolEditUrl,
    getTrainingSchoolsUrl: getTrainingSchoolsUrl,
    getCpdProviderPaymentsUrl: getCpdProviderPaymentsUrl,
    getCpdProvidersUrl: getCpdProvidersUrl,
    modifyDeleteCpdUrl: modifyDeleteCpdUrl,
    getAccreditedCpdsUrl: getAccreditedCpdsUrl,
    approveCpdApplicationUrl: approveCpdApplicationUrl,
    getCpdApplicationUrl: getCpdApplicationUrl,
    getCpdApplicationsUrl: getCpdApplicationsUrl,
    deleteCpdAcountUrl: deleteCpdAcountUrl,
    enableCpdEditUrl: enableCpdEditUrl,
    updateCpdProfileUrl: updateCpdProfileUrl,
    getCpdProviderUrl: getCpdProviderUrl,
    getTrainingHospitalPaymentsUrl: getTrainingHospitalPaymentsUrl,
    changeTrainingHospitalStatusUrl: changeTrainingHospitalStatusUrl,
    getTrainingHospitalsUrl: getTrainingHospitalsUrl,
    deleteAccreditedHospitalUrl: deleteAccreditedHospitalUrl,
    updateAccreditedHospitalUrl: updateAccreditedHospitalUrl,
    getAccreditedHospitalsUrl: getAccreditedHospitalsUrl,
    approveHospitalApplicationUrl: approveHospitalApplicationUrl,
    getHospitalApplicationUrl: getHospitalApplicationUrl,
    getTrainingHospitalApplicationsUrl: getTrainingHospitalApplicationsUrl,
    createNotificationUrl: createNotificationUrl,
    deleteNotificationUrl: deleteNotificationUrl,
    getNotificationsUrl: getNotificationsUrl,
    deleteExitRegisterReportUrl: deleteExitRegisterReportUrl,
    deletePreviousAssessmentBatchUrl: deletePreviousAssessmentBatchUrl,
    getPreviousAssessmentEntriesUrl: getPreviousAssessmentEntriesUrl,
    addPreviousAssessmentEntryUrl: addPreviousAssessmentEntryUrl,
    saveAssessmentApplicantDataUrl: saveAssessmentApplicantDataUrl,
    approveAssessmentApplicationUrl: approveAssessmentApplicationUrl,
    getAssessmentApplicantUrl: getAssessmentApplicantUrl,
    getAssessmentApplicationsUrl: getAssessmentApplicationsUrl,
    getSearchedHospitalsUrl: getSearchedHospitalsUrl,
    changeHospitalStatusUrl: changeHospitalStatusUrl,
    deleteHospitalUrl: deleteHospitalUrl,
    getHospitalsUrl: getHospitalsUrl,
    createHospitalUrl: createHospitalUrl,
    deleteAssessmentTypeUrl: deleteAssessmentTypeUrl,
    addAssessmentTypeUrl: addAssessmentTypeUrl,
    getAssessmentTypesUrl: getAssessmentTypesUrl,
    getSearchedBatchesUrl: getSearchedBatchesUrl,
    changeBatchStatusUrl: changeBatchStatusUrl,
    deleteAssessmentBatchUrl: deleteAssessmentBatchUrl,
    completeAssessmentBatchUrl: completeAssessmentBatchUrl,
    deleteBatchPaymentBreakdownUrl: deleteBatchPaymentBreakdownUrl,
    addPaymentBreakdownUrl: addPaymentBreakdownUrl,
    getBatchPaymentBreakdownsUrl: getBatchPaymentBreakdownsUrl,
    deleteBatchPaymentUrl: deleteBatchPaymentUrl,
    addAssessmentBatchPaymentUrl: addAssessmentBatchPaymentUrl,
    getBatchPaymentsUrl: getBatchPaymentsUrl,
    getBatchesUrl: getBatchesUrl,
    addAssessmentBatchUrl: addAssessmentBatchUrl,
    rootUrl: rootUrl,
    logoutUserUrl: logoutUserUrl,
    loginUserUrl: loginUserUrl,
    authorizationToken: authorizationToken,
    perPage: perPage,
    exportPerSheet: exportPerSheet,
    profileUserUrl: profileUserUrl,
    dashboardInfoUrl: dashboardInfoUrl,
    loadingBladeUrl: loadingBladeUrl,
    pendingSignupsUrl: pendingSignupsUrl,
    updatePendingSignupsUrl: updatePendingSignupsUrl,
    applicationTypeUrl: applicationTypeUrl,
    getExactApplicationsUrl: getExactApplicationsUrl,
    applicationUrl: applicationUrl,
    locationsUrl: locationsUrl,
    updateUserCpdPointUrl: updateUserCpdPointUrl,
    updateUserDataUrl: updateUserDataUrl,
    approveApplicationUrl: approveApplicationUrl,
    rejectApplicationUrl: rejectApplicationUrl,
    getAUserUrl: getAUserUrl,
    updateUserProfileUrl: updateUserProfileUrl,
    getUserAddQualUrl: getUserAddQualUrl,
    getAdditionalQulificationsUrl: getAdditionalQulificationsUrl,
    getInstitutionsUrl: getInstitutionsUrl,
    getAdminUnitsUrl: getAdminUnitsUrl,
    getUnitMembersUrl: getUnitMembersUrl,
    getMemberPrivilegesUrl: getMemberPrivilegesUrl,
    updateMemberPrivilegeUrl: updateMemberPrivilegeUrl,
    enableUserEditUrl: enableUserEditUrl,
    searchExactApplicationsUrl: searchExactApplicationsUrl,
    getRegistrationTypesUrl: getRegistrationTypesUrl,
    getSubRegistrationTypesUrl: getSubRegistrationTypesUrl,
    updateAdditionalQualificationUrl: updateAdditionalQualificationUrl,
    getUserApplicationsUrl: getUserApplicationsUrl,
    getApplicationTypesUrl: getApplicationTypesUrl,
    getUserProfileUrl: getUserProfileUrl,
    getRemitaPaymentStatusUrl: getRemitaPaymentStatusUrl,
    updateApplicationTransactionUrl: updateApplicationTransactionUrl,
    getUserMedicalSchoolUrl: getUserMedicalSchoolUrl,
    updateMedicalSchoolRecordUrl: updateMedicalSchoolRecordUrl,
    getIndemnityUrl: getIndemnityUrl,
    updateInsuranceRecordUrl: updateInsuranceRecordUrl,
    getInternshipRecordsUrl: getInternshipRecordsUrl,
    changePasswordUrl: changePasswordUrl,
    updateUserLicenseYearUrl: updateUserLicenseYearUrl,
    deleteUserAcountUrl: deleteUserAcountUrl,
    loadRRRDetailsUrl: loadRRRDetailsUrl,
    removeRRRPaymentUrl: removeRRRPaymentUrl,
    getOldAQApplicationUrl: getOldAQApplicationUrl,
    approveOldAQApplicationUrl: approveOldAQApplicationUrl,
    uploadEcisFileUrl: uploadEcisFileUrl,
    sendUserEcisMailUrl: sendUserEcisMailUrl,
    getAdminDepartmentsUrl: getAdminDepartmentsUrl,
    saveDepartmentUrl: saveDepartmentUrl,
    deleteAdminDepartmentUrl: deleteAdminDepartmentUrl,
    saveUnitUrl: saveUnitUrl,
    deleteAdminUnitUrl: deleteAdminUnitUrl,
    updateUserUnitUrl: updateUserUnitUrl,
    enableAdminUserUrl: enableAdminUserUrl,
    getAdminUsersUrl: getAdminUsersUrl,
    createAdminUserUrl: createAdminUserUrl,
    searchAdminUserUrl: searchAdminUserUrl,
    getDepartmentMembersUrl: getDepartmentMembersUrl,
    getPaymentItemsUrl: getPaymentItemsUrl,
    updatePaymentBreakdownUrl: updatePaymentBreakdownUrl,
    deletePaymentBreakdownUrl: deletePaymentBreakdownUrl,
    updatePaymentSurchargeUrl: updatePaymentSurchargeUrl,
    getEmailItemsUrl: getEmailItemsUrl,
    updateEmailUrl: updateEmailUrl,
    getSpecializationsUrl: getSpecializationsUrl,
    updateSpecializationUrl: updateSpecializationUrl,
    deleteUserSpecializationUrl: deleteUserSpecializationUrl,
    updateSubSpecializationUrl: updateSubSpecializationUrl,
    getAQconfigsUrl: getAQconfigsUrl,
    updateQualificationUrl: updateQualificationUrl,
    deleteUserQualificationUrl: deleteUserQualificationUrl,
    getCountriesUrl: getCountriesUrl,
    updateCountryUrl: updateCountryUrl,
    deleteUserCountryUrl: deleteUserCountryUrl,
    getStatesUrl: getStatesUrl,
    updateStateUrl: updateStateUrl,
    deleteUserStateUrl: deleteUserStateUrl,
    updateLgaUrl: updateLgaUrl,
    deleteUserLgaUrl: deleteUserLgaUrl,
    getAppointmentPostingsUrl: getAppointmentPostingsUrl,
    updatePostingDepartmentUrl: updatePostingDepartmentUrl,
    deleteAppointmentPostingUrl: deleteAppointmentPostingUrl,
    getSiteParametersUrl: getSiteParametersUrl,
    updateSiteParameterUrl: updateSiteParameterUrl,
    getPaymentsUrl: getPaymentsUrl,
    getFilteredPaymentsUrl: getFilteredPaymentsUrl,
    getSanctionsUrl: getSanctionsUrl,
    getSearchedSanctionsUrl: getSearchedSanctionsUrl,
    removeUserSanctionUrl: removeUserSanctionUrl,
    getUsersUrl: getUsersUrl,
    addUserSanctionUrl: addUserSanctionUrl,
    getExitRegisterUrl: getExitRegisterUrl,
    searchExitRegisterUrl: searchExitRegisterUrl,
    removeUserExitRegisterUrl: removeUserExitRegisterUrl,
    addUserExitRegisterUrl: addUserExitRegisterUrl,
    getSchoolsUrl: getSchoolsUrl,
    removeSchoolUrl: removeSchoolUrl,
    createSchoolUrl: createSchoolUrl,
    updateSchoolStatusUrl: updateSchoolStatusUrl,
    getApplicationsUrl: getApplicationsUrl,
    getFilteredApplicationUrl: getFilteredApplicationUrl,
    searchAQconfigsUrl: searchAQconfigsUrl,
    searchSpecializationsUrl: searchSpecializationsUrl,
    searchCountriesUrl: searchCountriesUrl,
    getAuditTrailRecordsUrl: getAuditTrailRecordsUrl,
    getFilteredAuditTrailRecordsUrl: getFilteredAuditTrailRecordsUrl,
    getSearchedSchoolsUrl: getSearchedSchoolsUrl,
    getDoctorsProfileUrl: getDoctorsProfileUrl,
    getFilteredDoctorsProfileUrl: getFilteredDoctorsProfileUrl,
    deleteUserAqUrl: deleteUserAqUrl,
    getDashboardInfoUrl: getDashboardInfoUrl,
    addOfflineSanctionListUrl: addOfflineSanctionListUrl,
    addOfflineExitRegisterUrl: addOfflineExitRegisterUrl,
    getExactComplaintsUrl: getExactComplaintsUrl,
    changeComplaintStatusUrl: changeComplaintStatusUrl,
    getExactReportsUrl: getExactReportsUrl,
    changeReportStatusUrl: changeReportStatusUrl,
    getExactExitRegisterReportsUrl: getExactExitRegisterReportsUrl,
    changeExitRegisterReportStatusUrl: changeExitRegisterReportStatusUrl,
    addToQuacksListUrl: addToQuacksListUrl,
    getQuacksUrl: getQuacksUrl,
    removeQuackFromListUrl: removeQuackFromListUrl,
    getSearchedQuacksUrl: getSearchedQuacksUrl,
};

const getters = {
    getRootUrl: state => state.rootUrl,
    getAuthorizationToken: state => state.authorizationToken,
    getLogoutUserUrl: state => state.logoutUserUrl,
    getLoginUserUrl: state => state.loginUserUrl,
    loadingBladeUrl: state => state.loadingBladeUrl,
    userPhotoUrl: state => state.rootUrl+'/storage/user',
    cpdPhotoUrl: state => state.rootUrl+'/storage/cpd/cpd',
    schoolPhotoUrl: state => state.rootUrl+'/storage/schools/school',
    blankImageUrl: () => blankImageUrl,
    getResetPasswordUrl: () => resetPasswordUrl,
    printCertificateUrl: () => printCertificateUrl,
    pdfUrl: () => pdfUrl,
    exportPerSheet: () => exportPerSheet,
    assessmentPrintRange: () => assessmentPrintRange,
    getupdatePasswordUrl: () => updatePasswordUrl,
    getExportRemedationListUrl: () => getExportRemedationListUrl
};

export default {
    state,
    getters
}